import React from "react";
import Header from "./header";
import Footer from "../components/common/footer";
import Supportdetails from "../components/common/supportdetails";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext ,Link} from "gatsby-plugin-react-i18next";
// import {defaultRelationAlternatives} from "../utils/pages"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL

const Contactus = () => {

  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/contact-us/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/contact-us/`))
      
  const canoncalUrl = buildCanonicalUrl();
  
  const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();
  const { t } = useTranslation()
  
  return (
    <div>
      <div className="Layout">
        <Header />
        <Supportdetails />
        <Footer />
        <Helmet>
          <script
            dangerouslySetInnerHTML={{
              __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
            }}
          />
          <meta charSet="utf-8" />
          <title>{t("Contact Logipulse - Logistics Support & Services")}</title>
          <Link rel="canonical" href={canoncalUrl} />
          <Link rel="alternate" href={hrefUrl} hreflang={language} />
           <meta
            name="description"
            content={t("At Logipulse, our trained personnel offer optimal support and maintenance services for air, land, and ocean freight software, WPS software, 3PL solutions and more. Contact Logipulse and get hassle-free logistics support services.")}
          />
          <meta
            name="Keywords"
            content={t("logipulse contact information, logipulse support services, logistics support and maintenance services")}
            data-react-helmet="true"
          />      
         
        </Helmet>
      </div>
    </div>
  )
}
export default Contactus
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
