import axios from 'axios';

const logipulseCRMUrl = process.env.GATSBY_LOGIPULSE_CRM_URL;

const saveContactDetails = async (inputData) => {
    let apiURL = `${logipulseCRMUrl}/logipulseCRM/createContactUs`;

    let response = await axios.post(apiURL, inputData);
    return response;
}


export {
    saveContactDetails,
}